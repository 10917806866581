
import { isValid, format, parseISO } from "date-fns";
import { communicationMapState } from "@/store/modules/communication";
import Vue from "vue";
import { unescape } from "lodash";
export default Vue.extend({
  name: "dispatched-single-recipient-email-communication",
  components: {
    EmailRecipients: () => import("./EmailRecipients.vue"),
    CommunicationEmailMetrics: () => import("./CommunicationEmailMetrics.vue")
  },
  methods: {
    formatDate(dateString: string) {
      const potentialDate = parseISO(dateString);
      if (isValid(potentialDate))
        return format(potentialDate, "M/d/yyyy h:mm:ss a");
      else return "N/A";
    }
  },
  computed: {
    ...communicationMapState(["editing"]),
    communication(): any {
      return this.editing;
    },
    formattedDeliveryMethods(): string {
      if (this.communication) {
        return this.communication.alternateDeliveryMethods
          .map((method: string) => method.toUpperCase())
          .join(" - ");
      } else {
        return "N/A";
      }
    },
    formattedPriority(): string {
      if (this.communication) return this.communication.priority.toUpperCase();
      else return "N/A";
    },
    notificationMessage(): string {
      const message = this.editing.message || "";
      return unescape(message);
    }
  }
});
